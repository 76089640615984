<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Business Partner Master Data</v-toolbar-title>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          v-if="docRights && docRights.write"
          color="toolbarIcon"
          text
          to="/new-bp-master-data"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              dense
              v-if="docRights.read == true"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="bpMasterData"
              :search="search"
            >
              <template v-slot:item.CardName="{ item }">
                <v-btn
                  class="btnLink"
                  color="flatButton"
                  text
                  :to="`/bp-master-data/${item.id}`"
                >{{ item.CardName }}</v-btn>
              </template>
            </v-data-table>

            <v-alert
              type="error"
              v-if="docRights.read == false"
            >You are not authorised to read data</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    docRights: {},
    search: "",
    bpMasterData: [],
    headers: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
      // { text: "Foreign Name", value: "CardFName" },
      { text: "Region", value: "territory.descript" },
      // { text: "Group", value: "GroupCode" },
      { text: "Longitude", value: "Longitude" },
      { text: "Latitude", value: "Latitude" },
      { text: "Mapped by", value: "SlpCode" },
    ],
  }),
  methods: {
    getBpMaster() {
      const self = this;

      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          console.log(res, "bp master");
          self.bpMasterData = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    checkRights() {
      const self = this;
      this.$store
        .dispatch("get", `/authorization/check-if-permitted/1`)
        .then((res) => {
          self.docRights = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getBpMaster();
    this.checkRights();
  },
};
</script>